<template>
  <!-- Contact Subpage -->
  <section data-id="contact" class="animated-section">
    <div class="section-content">
      <div class="page-title">
        <h2>Contact</h2>
      </div>

      <div class="row">
        <!-- Contact Info -->
        <div class="col-xs-12 col-sm-4">
          <div class="lm-info-block gray-default">
            <i class="lnr lnr-map-marker"></i>
            <h4>Alaska, USA</h4>
            <span class="lm-info-block-value"></span>
            <span class="lm-info-block-text"></span>
          </div>

          <div class="lm-info-block gray-default">
            <i class="lnr lnr-phone-handset"></i>
            <h4>860-375-0697</h4>
            <span class="lm-info-block-value"></span>
            <span class="lm-info-block-text"></span>
          </div>

          <div class="lm-info-block gray-default">
            <i class="lnr lnr-envelope"></i>
            <h4>me@skoll.dev</h4>
            <span class="lm-info-block-value"></span>
            <span class="lm-info-block-text"></span>
          </div>

          <div class="lm-info-block gray-default">
            <i class="lnr lnr-checkmark-circle"></i>
            <h4>Freelance Available</h4>
            <span class="lm-info-block-value"></span>
            <span class="lm-info-block-text"></span>
          </div>
        </div>
        <!-- End of Contact Info -->

        <!-- Contact Form -->
        <div class="col-xs-12 col-sm-8">
          <div class="lmpixels-map">
            <iframe
              frameborder="0"
              scrolling="no"
              marginheight="0"
              marginwidth="0"
            ></iframe>
          </div>
          <div class="block-title">
            <h3>How Can I <span>Help You?</span></h3>
          </div>
          <!-- WIP Displaying my email for now -->

          <div class="contact_form">
            <p>
              If you have any questions, please feel free to contact me. I will
              get back to you as soon as possible.
            </p>
          </div>

          <!-- <form
            id="contact_form"
            class="contact-form"
            action="contact_form/contact_form.php"
            method="post"
          >
            <div class="messages"></div>

            <div class="controls two-columns">
              <div class="fields clearfix">
                <div class="left-column">
                  <div class="form-group form-group-with-icon">
                    <input
                      id="form_name"
                      type="text"
                      name="name"
                      class="form-control"
                      placeholder=""
                      required="required"
                      data-error="Name is required."
                    />
                    <label>Full Name</label>
                    <div class="form-control-border"></div>
                    <div class="help-block with-errors"></div>
                  </div>

                  <div class="form-group form-group-with-icon">
                    <input
                      id="form_email"
                      type="email"
                      name="email"
                      class="form-control"
                      placeholder=""
                      required="required"
                      data-error="Valid email is required."
                    />
                    <label>Email Address</label>
                    <div class="form-control-border"></div>
                    <div class="help-block with-errors"></div>
                  </div>

                  <div class="form-group form-group-with-icon">
                    <input
                      id="form_subject"
                      type="text"
                      name="subject"
                      class="form-control"
                      placeholder=""
                      required="required"
                      data-error="Subject is required."
                    />
                    <label>Subject</label>
                    <div class="form-control-border"></div>
                    <div class="help-block with-errors"></div>
                  </div>
                </div>
                <div class="right-column">
                  <div class="form-group form-group-with-icon">
                    <textarea
                      id="form_message"
                      name="message"
                      class="form-control"
                      placeholder=""
                      rows="7"
                      required="required"
                      data-error="Please, leave me a message."
                    ></textarea>
                    <label>Message</label>
                    <div class="form-control-border"></div>
                    <div class="help-block with-errors"></div>
                  </div>
                </div>
              </div>

              <div
                class="g-recaptcha"
                data-sitekey="6LdqmCAUAAAAAMMNEZvn6g4W5e0or2sZmAVpxVqI"
                data-theme="dark"
              ></div>

              <input
                type="submit"
                class="button btn-send"
                value="Send message"
              />
            </div>
          </form> -->
        </div>
        <!-- End of Contact Form -->
      </div>
    </div>
  </section>
  <!-- End of Contact Subpage -->
</template>

<script>
export default {
  name: "ContactSec",
};
</script>

<style></style>
