<template>
  <div>
    <NavArrows />

    <div class="content-area">
      <div class="animated-sections">
        <HomeSec />
        <AboutSec />
        <ResumeSec />
        <PortfolioSec />
        <BlogSec />
        <ContactSec />
      </div>
    </div>
  </div>
</template>

<script>
import NavArrows from "../ui/NavArrows.vue";
import HomeSec from "../sections/HomeSec.vue";
import AboutSec from "../sections/AboutSec.vue";
import ResumeSec from "../sections/ResumeSec.vue";
import PortfolioSec from "../sections/PortfolioSec.vue";
import BlogSec from "../sections/BlogSec.vue";
import ContactSec from "../sections/ContactSec.vue";

export default {
  name: "MainPage",
    components: {
        NavArrows,
        HomeSec,
        AboutSec,
        ResumeSec,
        PortfolioSec,
        BlogSec,
        ContactSec,
    },
};
</script>

<style></style>
