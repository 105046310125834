import { createRouter, createWebHistory } from 'vue-router';
import MainPage from '../components/pages/MainPage.vue';
import BlogPage from '../components/pages/BlogPage.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: MainPage
  },
  // Add other routes here
    {
        path: '/blog',
        name: 'Blog',
        component: BlogPage
    },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;