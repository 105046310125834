<template>
  <!-- Arrows Nav -->
  <div class="lmpixels-arrows-nav">
    <div class="lmpixels-arrow-right">
      <i class="lnr lnr-chevron-right"></i>
    </div>
    <div class="lmpixels-arrow-left">
      <i class="lnr lnr-chevron-left"></i>
    </div>
  </div>
  <!-- End Arrows Nav -->
</template>

<script>
export default {
  name: "NavArrows",
};
</script>

<style></style>
