<template>
  <!-- Mobile Navigation -->
  <div class="menu-toggle">
    <span></span>
    <span></span>
    <span></span>
  </div>
  <!-- End Mobile Navigation -->
</template>

<script>
export default {
  name: "MobileNav",
};
</script>

<style></style>
