<template>
  <div class="page-content">
    <AppHeader />
    <MobileNav />
    <router-view />
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import AppHeader from './components/ui/AppHeader.vue';
import MobileNav from './components/ui/MobileNav.vue';

export default defineComponent({
  components: {
    AppHeader,
    MobileNav,
  },
});
</script>

<style scoped>
/* Add your styles here */
</style>