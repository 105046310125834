<template>
  <div class="content-area single-page-area">
    <div class="single-page-content">
      <article class="post">
        <div class="post-thumbnail">
          <img src="img/blog/blog_post_1_full.jpg" alt="image" />
        </div>

        <div class="post-content">
          <!-- /Entry header -->
          <header class="entry-header">
            <!-- Entry meta -->
            <div class="entry-meta entry-meta-top">
              <span><a href="#" rel="category tag">UI</a></span>
            </div>
            <!-- /Entry meta -->

            <h2 class="entry-title">
              Best Practices for Animated Progress Indicators
            </h2>
          </header>
          <!-- /Entry header -->

          <!-- Entry content -->
          <div class="entry-content">
            <div class="row">
              <div class="col-xs-12 col-sm-12">
                <div class="col-inner">
                  <p>
                    Nulla nulla nisl, sodales ac nulla ac, consequat vulputate
                    purus. Curabitur tincidunt ipsum vel nibh rutrum accumsan.
                    Nunc ullamcorper posuere leo, vitae aliquet risus pharetra
                    in. Integer turpis eros, iaculis et mi non, pulvinar egestas
                    leo. Etiam sagittis ex turpis, vitae cursus tortor interdum
                    eu. Quisque ultrices nunc eget erat vestibulum euismod. Ut
                    mauris nisi, facilisis at arcu nec, facilisis porttitor
                    lorem.
                  </p>
                  <p>
                    Vivamus vitae neque molestie, porta libero sed, tincidunt
                    leo. In nec posuere odio, id rhoncus lorem. Proin id erat ut
                    dolor condimentum viverra. Praesent viverra sed dolor ac
                    luctus. Praesent placerat id lorem quis lacinia.
                  </p>

                  <blockquote class="quote">
                    Maecenas id finibus felis. Etiam vitae nibh et felis
                    efficitur pellentesque. Mauris suscipit sapien nunc, a
                    lacinia nibh feugiat ut. In hac habitasse platea dictumst.
                    <footer class="quote-author">
                      <span>Larry L. Johnson</span>
                    </footer>
                  </blockquote>

                  <p>
                    Etiam interdum vulputate risus, vitae elementum neque
                    consectetur sed. Donec at risus dui. Ut in suscipit neque.
                    Vestibulum sit amet lobortis magna, commodo venenatis ante.
                    Cras molestie, ex a auctor lacinia, risus est aliquam risus,
                    sit amet semper purus tortor id ante. Donec lacus ipsum,
                    porttitor et libero a, fringilla auctor quam. Sed in nisl id
                    libero tincidunt aliquet. Aenean dui ipsum, auctor ut leo
                    ut, semper dignissim lacus. Suspendisse faucibus viverra
                    consequat. Maecenas efficitur massa vel eros sagittis
                    dapibus. Nam lobortis mi in turpis hendrerit eleifend. Nulla
                    non massa felis.
                  </p>

                  <p>
                    Donec sit amet dolor ante. Vivamus vel massa accumsan,
                    faucibus quam quis, convallis velit. Aliquam erat volutpat.
                    Integer imperdiet diam quis arcu venenatis, quis sagittis
                    nibh rhoncus. Donec non nisi scelerisque, sodales metus
                    quis, accumsan mauris. Curabitur volutpat risus rutrum erat
                    condimentum tristique. Nullam at felis diam. Quisque dictum
                    felis non ante pretium mollis. Aliquam turpis neque, varius
                    nec diam a, aliquam pulvinar diam. Interdum et malesuada
                    fames ac ante ipsum primis in faucibus. Sed ipsum libero,
                    aliquet sed bibendum faucibus, semper a dui.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <!-- /Entry content -->

          <div class="entry-meta entry-meta-bottom">
            <div class="date-author">
              <span class="entry-date">
                <a href="#" rel="bookmark">
                  <i class="far fa-clock"></i>
                  <span class="entry-date"> March 16, 2020</span>
                </a>
              </span>
              <span class="author vcard">
                <a class="url fn n" href="#" rel="author">
                  <i class="fas fa-user"></i> LMPixels</a
                >
              </span>
            </div>

            <!-- Share Buttons -->
            <div class="entry-share btn-group share-buttons">
              <a
                href="#"
                onclick="javascript:window.open(this.href, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600');return false;"
                class="btn"
                target="_blank"
                title="Share on Facebook"
              >
                <i class="fab fa-facebook-f"></i>
              </a>

              <a
                href="#"
                onclick="javascript:window.open(this.href, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600');return false;"
                class="btn"
                target="_blank"
                title="Share on Twitter"
              >
                <i class="fab fa-twitter"></i>
              </a>

              <a
                href="#"
                onclick="javascript:window.open(this.href,'', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600');return false;"
                class="btn"
                title="Share on LinkedIn"
              >
                <i class="fab fa-linkedin-in"></i>
              </a>
            </div>
            <!-- /Share Buttons -->
          </div>

          <div class="post-tags">
            <div class="tags">
              <a href="#" rel="tag">animate</a>
              <a href="#" rel="tag">bar</a>
              <a href="#" rel="tag">design</a>
              <a href="#" rel="tag">progress</a>
              <a href="#" rel="tag">ui</a>
            </div>
          </div>
        </div>
      </article>
    </div>
  </div>
</template>

<script>
export default {
    name: "BlogPage",
};
</script>

<style></style>
