<template>
  <!-- Resume Subpage -->
  <section data-id="resume" class="animated-section">
    <div class="section-content">
      <div class="page-title">
        <h2>Resume</h2>
      </div>

      <div class="row">
        <div class="col-xs-12 col-sm-7">
          <div class="block-title">
            <h3>Professional Summary</h3>
          </div>
          <p>
            Seasoned IT professional leveraging over a decade of hands-on
            experience in DevOps, Network Engineering, and System
            Administration. Distinguished for my technical acumen, strategic
            vision, and commitment to operational excellence, I thrive on
            transforming IT infrastructure, fostering innovation, and driving
            efficiency in fast-paced, complex environments. I am highly adept in
            a broad spectrum of technologies, protocols, and methodologies that
            span across networking, cybersecurity, software development, and
            systems administration.
          </p>

          <div class="white-space-50"></div>

          <div class="block-title">
            <h3>Skills</h3>
          </div>
          <ul>
            <li>Network Engineering & Architecture</li>
            <li>Cybersecurity & Risk Management</li>
            <li>Cloud & Container Technologies: Docker, Kubernetes</li>
            <li>Programming: Java, Bash / Powershell</li>
            <li>DevOps & IT Operations</li>
            <li>System & Application Support</li>
            <li>Equipment Servicing & Structured Cabling</li>
            <li>Leadership & Active Listening</li>
          </ul>

          <div class="white-space-50"></div>

          <div class="block-title">
            <h3>Work History</h3>
          </div>
          <div class="timeline timeline-second-style clearfix">
            <div class="timeline-item clearfix">
              <div class="left-part">
                <h5 class="item-period">March 2023 - Current</h5>
                <span class="item-company"
                  >Joint Base Elmendorf-Richardson, USAF - Anchorage, AK</span
                >
              </div>
              <div class="divider"></div>
              <div class="right-part">
                <h4 class="item-title">Application Support Engineer</h4>
                <p>
                  Lead the resolution of over 300 technical issues, bolstering
                  end-user experience and system stability. Managed the
                  integration of software across 60 systems, optimizing user
                  client performance. Drove application enhancements and
                  maintenance on RHEL systems, prioritizing security. Supported
                  software integration and implemented maintenance enhancements.
                </p>
              </div>
            </div>

            <div class="timeline-item clearfix">
              <div class="left-part">
                <h5 class="item-period">March 2021 - March 2023</h5>
                <span class="item-company"
                  >Joint Base Elmendorf-Richardson, USAF - Anchorage, AK</span
                >
              </div>
              <div class="divider"></div>
              <div class="right-part">
                <h4 class="item-title">Data Center Technician</h4>
                <p>
                  Delivered comprehensive network support, managing Windows AD
                  for over 50 users and students. Introduced Zero Trust (ZT)
                  policies within DoD/NSA security models, amplifying system
                  security. Ensured seamless operations at remote EDGE locations
                  in Alaska's North Slope Region, maintaining vital IP services
                  over SATCOM connectivity.
                </p>
              </div>
            </div>

            <div class="timeline-item clearfix">
              <div class="left-part">
                <h5 class="item-period">August 2022 - February 2023</h5>
                <span class="item-company"
                  >Joint Base Elmendorf-Richardson, USAF - Anchorage, AK</span
                >
              </div>
              <div class="divider"></div>
              <div class="right-part">
                <h4 class="item-title">Full Stack Developer</h4>
                <p>
                  Played a key role as a DevSecOps Engineer, driving
                  organizational success through developing a SpringBoot
                  application for talent management. Administered Java and
                  TypeScript/React codebase on Platform One using GitLab,
                  significantly enhancing software performance. Developed and
                  managed database using NodeJS, ORM, SQL/No-SQL, increasing
                  operational efficiency. Reviewed code, debugged problems, and
                  corrected issues.
                </p>
              </div>
            </div>

            <div class="timeline-item clearfix">
              <div class="left-part">
                <h5 class="item-period">August 2018 - February 2021</h5>
                <span class="item-company"
                  >RAF Croughton, USAF - Croughton, England</span
                >
              </div>
              <div class="divider"></div>
              <div class="right-part">
                <h4 class="item-title">Telecommunications Technician</h4>
                <p>
                  Oversaw a 106-million-dollar Defense Information Network
                  facility, managing 519 circuits and supporting 76,000
                  distant-end users. Mentored junior technicians, overseeing a
                  32-million-dollar network with 99.98% uptime. Led an
                  infrastructure overhaul, eliminating 4800 ft of excess
                  cabling, and modernized Motorola and Cisco hardware through
                  targeted upgrades. Quickly resolved complex technical issues
                  and maintained optimal network performance through regular
                  system inspections and prompt response to service requests.
                  Managed customer issues for implementation and maintenance of
                  voice, data, VoIP, and CTI applications. Collaborated with
                  other technicians to troubleshoot and resolve technical
                  issues.
                </p>
              </div>
            </div>

            <div class="timeline-item clearfix">
              <div class="left-part">
                <h5 class="item-period">January 2020 - July 2020</h5>
                <span class="item-company"
                  >Al Udeid Air Base, USAF - Qatar</span
                >
              </div>
              <div class="divider"></div>
              <div class="right-part">
                <h4 class="item-title">Network Operations Center Technician</h4>
                <p>
                  Led the successful upgrade and management of a
                  65-million-dollar Falconer Weapon System, improving system
                  reliability and performance. Expertly troubleshoot system
                  outages during organization-wide tech refresh, minimizing
                  downtime. Troubleshot hardware and software to determine and
                  rectify network problems. Installed cabling, wireless routers
                  and telephone systems for data communications networks.
                </p>
              </div>
            </div>
          </div>
        </div>

        <!-- Skills & Certificates -->
        <div class="col-xs-12 col-sm-5">
          <!-- Coding Skills -->
          <div class="block-title">
            <h3>Skills</h3>
          </div>

          <div class="skills-info skills-second-style">
            <!-- Skill 1 -->
            <div class="skill clearfix">
              <h4>Network Engineering & Architecture</h4>
              <div class="skill-value">Expert</div>
            </div>
            <!-- End of Skill 1 -->

            <!-- Skill 2 -->
            <div class="skill clearfix">
              <h4>Cybersecurity & Risk Management</h4>
              <div class="skill-value">Expert</div>
            </div>
            <!-- End of Skill 2 -->

            <!-- Skill 3 -->
            <div class="skill clearfix">
              <h4>Cloud & Container Technologies: Docker, Kubernetes</h4>
              <div class="skill-value">Advanced</div>
            </div>
            <!-- End of Skill 3 -->

            <!-- Skill 4 -->
            <div class="skill clearfix">
              <h4>Programming: Java, Bash / Powershell</h4>
              <div class="skill-value">Advanced</div>
            </div>
            <!-- End of Skill 4 -->

            <!-- Skill 5 -->
            <div class="skill clearfix">
              <h4>DevOps & IT Operations</h4>
              <div class="skill-value">Expert</div>
            </div>
            <!-- End of Skill 5 -->

            <!-- Skill 6 -->
            <div class="skill clearfix">
              <h4>System & Application Support</h4>
              <div class="skill-value">Expert</div>
            </div>
            <!-- End of Skill 6 -->

            <!-- Skill 7 -->
            <div class="skill clearfix">
              <h4>Equipment Servicing & Structured Cabling</h4>
              <div class="skill-value">Advanced</div>
            </div>
            <!-- End of Skill 7 -->

            <!-- Skill 8 -->
            <div class="skill clearfix">
              <h4>Leadership & Active Listening</h4>
              <div class="skill-value">Advanced</div>
            </div>
            <!-- End of Skill 8 -->
          </div>
          <!-- End of Skills -->

          <div class="white-space-10"></div>

          <!-- Knowledges -->
          <div class="block-title">
            <h3>Certifications</h3>
          </div>

          <ul class="knowledges">
            <li>Cisco CCNA</li>
            <li>AWS Cloud Practitioner</li>
            <li>Linux Essentials</li>
            <li>
              CompTIA A+, Network+, Security+, Secure Infrastructure Specialist,
              IT Operations Specialist
            </li>
            <li>ITIL4, DevOps Fundamentals</li>
            <li>Cleared Professional</li>
          </ul>
          <!-- End of Knowledges -->
        </div>
        <!-- End of Skills & Certificates -->
      </div>

      <div class="white-space-50"></div>

      <div class="block-title">
        <h3>Education</h3>
      </div>

      <div class="timeline timeline-second-style clearfix">
        <div class="timeline-item clearfix">
          <div class="left-part">
            <h5 class="item-period">January 2024</h5>
            <span class="item-company">Department of Defense</span>
          </div>
          <div class="divider"></div>
          <div class="right-part">
            <h4 class="item-title">Platform Development and Sustainment Course</h4>
          </div>
        </div>
        <div class="timeline-item clearfix">
          <div class="left-part">
            <h5 class="item-period">November 2023</h5>
            <span class="item-company">Western Governors University</span>
          </div>
          <div class="divider"></div>
          <div class="right-part">
            <h4 class="item-title">
              Bachelor of Science Network Operations And Security
            </h4>
          </div>'
        </div>
          <!-- Inserting Schooling from CCAF (Community College of the Air Force July 2023) -->
          <!-- Insert Oporator Training Cource (DoD Training in January 2024) -->
        <div class="timeline-item clearfix">
          <div class="left-part">
            <h5 class="item-period">July 2023</h5>
            <span class="item-company">Community College of the Air Force</span>
          </div>
          <div class="divider"></div>
          <div class="right-part">
            <h4 class="item-title">Associate of Applied Science in Information Systems Technology</h4>
          </div>
        </div>
      </div>

      <div class="white-space-50"></div>

      <!-- Hobbies -->
      <div class="block-title">
        <h3>Hobbies</h3>
      </div>
      <p>
        Enthusiastic about paramotoring, mountain biking, and paintballing.
        Interested in the latest trends in the automotive industry. Adept at
        digital communication systems, programming, and 3D printing.
        Demonstrated ability to manage complex tasks and projects to completion,
        enhancing creativity, innovation, and technical dexterity.
      </p>
    </div>
  </section>
  <!-- End of Resume Subpage -->
</template>

<script>
export default {
  name: "ResumeSec",
};
</script>

<style>
/* Add your styles here */
</style>
