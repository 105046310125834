<template>
    <header id="site_header" class="header mobile-menu-hide">
    
    <HeaderContent />
    <MainMenu />
    <SocialLinks />

      <div class="header-buttons">
        <a href="#" target="_blank" class="btn btn-primary">Download CV</a>
      </div>
    <div class="copyrights">© {{ new Date().getFullYear() }} All rights reserved.</div>
    </header>
</template>

<script>
import SocialLinks from './SocialLinks.vue';
import MainMenu from './MainMenu.vue';
import HeaderContent from './HeaderContent.vue';

export default {
  name: 'AppHeader',
  components: {
    SocialLinks,
    MainMenu,
    HeaderContent
  }
}
</script>

<style>

</style>