<template>
  <!-- Portfolio Subpage -->
  <section data-id="portfolio" class="animated-section">
    <div class="section-content">
      <div class="page-title">
        <h2>Portfolio</h2>
      </div>

      <div class="row">
        <div class="col-xs-12 col-sm-12">
          <!-- Portfolio Content -->
          <div class="portfolio-content">
            <ul class="portfolio-filters">
              <li class="active">
                <a class="filter btn btn-sm btn-link" data-group="category_all"
                  >All</a
                >
              </li>
              <li>
                <a
                  class="filter btn btn-sm btn-link"
                  data-group="category_websites"
                  >Websites</a
                >
              </li>
              <li>
                <a
                  class="filter btn btn-sm btn-link"
                  data-group="category_deployments"
                  >Deployments</a
                >
              </li>
              <li>
                <a
                  class="filter btn btn-sm btn-link"
                  data-group="category_photography"
                  >Photography</a
                >
              </li>
              <li>
                <a
                  class="filter btn btn-sm btn-link"
                  data-group="category_soundcloud"
                  >SoundCloud</a
                >
              </li>
              <li>
                <a
                  class="filter btn btn-sm btn-link"
                  data-group="category_youtube"
                  >YouTube Videos</a
                >
              </li>
            </ul>

            <!-- Portfolio Grid -->
            <div class="portfolio-grid three-columns">
              <figure
                class="item lbimage"
                data-groups='["category_all", "category_websites"]'
              >
                <div class="portfolio-item-img">
                  <img
                    src="img/portfolio/1.jpg"
                    alt="Library Website"
                    title=""
                  />
                  <a
                    href="https://www.rockvillepubliclibrary.org/"
                    class="lightbox mfp-iframe"
                    title="Library Website"
                  ></a>
                </div>

                <i class="fa fa-globe"></i>
                <h4 class="name">Library Website</h4>
                <span class="category">Websites</span>
              </figure>

              <figure
                class="item lbimage"
                data-groups='["category_all", "category_websites"]'
              >
              <div class="portfolio-item-img">
                  <img
                    src="img/portfolio/1.jpg"
                    alt="Library Website"
                    title=""
                  />
                  <a
                    href="https://thevernonfoundation.org/"
                    class="lightbox mfp-iframe"
                    title="Charity Website"
                  ></a>
                </div>

                <i class="fa fa-globe"></i>
                <h4 class="name">Vernon Foundation</h4>
                <span class="category">Websites</span>
              </figure>

              <figure
                class="item lbimage"
                data-groups='["category_all", "category_websites"]'
              >
              <div class="portfolio-item-img">
                  <img
                    src="img/portfolio/1.jpg"
                    alt="Paintball Website"
                    title=""
                  />
                  <a
                    href="https://www.mattsoutback.com/"
                    class="lightbox mfp-iframe"
                    title="Library Website"
                  ></a>
                </div>

                <i class="fa fa-globe"></i>
                <h4 class="name">Matt's Outback Paintball</h4>
                <span class="category">Websites</span>
              </figure>

              <figure
                class="item lbimage"
                data-groups='["category_all", "category_websites"]'
              >
              <div class="portfolio-item-img">
                  <img
                    src="img/portfolio/1.jpg"
                    alt="Moving Website"
                    title=""
                  />
                  <a
                    href="https://manchestermovers.com/"
                    class="lightbox mfp-iframe"
                    title="Library Website"
                  ></a>
                </div>

                <i class="fa fa-globe"></i>
                <h4 class="name">Moving Website</h4>
                <span class="category">Websites</span>
              </figure>

              <figure
                class="item lbimage"
                data-groups='["category_all", "category_websites"]'
              >
              <div class="portfolio-item-img">
                  <img
                    src="img/portfolio/1.jpg"
                    alt="Briley Website"
                    title=""
                  />
                  <a
                    href="https://brileyassociates.com/"
                    class="lightbox mfp-iframe"
                    title="Briley Website"
                  ></a>
                </div>

                <i class="fa fa-globe"></i>
                <h4 class="name">Briley Associates</h4>
                <span class="category">Websites</span>
              </figure>

              <figure
                class="item lbvideo"
                data-groups='["category_all", "category_youtube"]'
              >
                <div class="portfolio-item-img">
                  <img
                    src="img/portfolio/6.jpg"
                    alt="YouTube Video 1"
                    title=""
                  />
                  <a
                    href="https://www.youtube.com/embed/f014diLgZTQ"
                    class="lightbox mfp-iframe"
                    title="YouTube Video 1"
                  ></a>
                </div>

                <i class="fas fa-video"></i>
                <h4 class="name">Ray Allen Camp</h4>
                <span class="category">Youtube Video</span>
              </figure>

              <figure
                class="item lbvideo"
                data-groups='["category_all", "category_youtube"]'
              >
                <div class="portfolio-item-img">
                  <img
                    src="img/portfolio/6.jpg"
                    alt="YouTube Video 1"
                    title=""
                  />
                  <a
                    href="https://www.youtube.com/embed/Zpbwfycm8cM"
                    class="lightbox mfp-iframe"
                    title="Youtube Video"
                  ></a>
                </div>

                <i class="fas fa-video"></i>
                <h4 class="name">Costa Rica</h4>
                <span class="category">Youtube Video</span>
              </figure>

              <figure
                class="item lbvideo"
                data-groups='["category_all", "category_youtube"]'
              >
                <div class="portfolio-item-img">
                  <img
                    src="img/portfolio/6.jpg"
                    alt="YouTube Video 1"
                    title=""
                  />
                  <a
                    href="https://www.youtube.com/embed/O778P7CsYa8"
                    class="lightbox mfp-iframe"
                    title="YouTube Video 1"
                  ></a>
                </div>

                <i class="fas fa-video"></i>
                <h4 class="name">Vernon Drone Video</h4>
                <span class="category">Youtube Video</span>
              </figure>

              <figure
                class="item lbvideo"
                data-groups='["category_all", "category_youtube"]'
              >
                <div class="portfolio-item-img">
                  <img
                    src="img/portfolio/6.jpg"
                    alt="YouTube Video 1"
                    title=""
                  />
                  <a
                    href="https://www.youtube.com/embed/RBjG2RfQUoM"
                    class="lightbox mfp-iframe"
                    title="YouTube Video 1"
                  ></a>
                </div>

                <i class="fas fa-video"></i>
                <h4 class="name">Vernon Drone Video 2</h4>
                <span class="category">Youtube Video</span>
              </figure>

            </div>
          </div>
          <!-- End of Portfolio Content -->
        </div>
      </div>
    </div>
  </section>
  <!-- End of Portfolio Subpage -->
</template>

<script>
export default {
  name: "PortfolioSec",
};
</script>

<style></style>
