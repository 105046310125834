<template>
  <div class="social-links">
    <ul>
      <li>
        <a href="https://www.linkedin.com/in/bryce-fountain/" target="_blank"><i class="fab fa-linkedin-in"></i></a>
      </li>
      <li>
        <a href="https://github.com/DevSkoll" target="_blank"><i class="fab fa-github"></i></a>
      </li>
      <!-- <li>
        <a href="#" target="_blank"><i class="fab fa-twitter"></i></a>
      </li> -->
    </ul>
  </div>
</template>

<script>
export default {
  name: 'SocialLinks'
}
</script>

<style>
</style>
