<template>
  <!-- Home Subpage -->
  <section data-id="home" class="animated-section start-page">
    <div class="section-content vcentered">
      <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-12">
          <div class="title-block">
            <h2>Bryce Fountain</h2>
            <div class="owl-carousel text-rotation">
              <div class="item">
                <div class="sp-subtitle">Platform Engineer</div>
              </div>

              <div class="item">
                <div class="sp-subtitle">Proudly Serving the USA</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- End of Home Subpage -->
</template>

<script>
export default {
  name: "HomeSec",
};
</script>

<style></style>
