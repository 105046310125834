<template>
  <div class="header-content">
    <div class="header-photo">
      <img src="img/main_photo.jpg" alt="Alex Smith" />
    </div>
    <div class="header-titles">
      <h2>Bryce Fountain</h2>
      <h4>Platform Engineer</h4>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HeaderContent'
}
</script>

<style>
</style>
